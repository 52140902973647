
import React, { useEffect, useRef, useState } from 'react'
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Select,
    message,
    Checkbox,
    Switch,
    Upload,
    Space,
    TimePicker,
    Button
} from "antd";
import styled from "@emotion/styled";
import moment from "moment";
import {
    NON_EMPTY_REGEX,
    EMAIL_REGEX,
    countryList,
    Greetings,
    AwardList,
    STATUS_CODE,
} from "common/Constants";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isEmpty } from 'lodash';
import { CALL_API } from 'common/API';


const StyledButton = styled(Button)`
  background: #26a4ff;
  border-radius: 5px;
  color: #ffffff;
  width: 100px;
  margin: 10px;
`;

const StyleRow = styled(Row)`
  padding: 10px;
  box-shadow: 0px 3px 6px #00000015;
  .ant-form-item-label {
    text-align: left;
  }
  .ant-picker{
    width: 100% !important;
  }
  .ant-upload.ant-upload-select {
    width: 100% !important;
    color: #397ff4 !important;
    .ant-btn > span {
      display: inline-block;
      color: #397ff4;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      background: #f2f2f2;
    }
    .ant-upload-list-picture .ant-upload-list-item,
    .ant-upload-list-picture-card .ant-upload-list-item {
      height: 40px;
    }
    .ant-upload-list-text .ant-upload-list-item-name,
    .ant-upload-list-picture .ant-upload-list-item-name {
      color: #397ff4;
    }
  }
`;

function PersonalForm({ setStep, setFormValues, formValues, form }) {

    const { Option } = Select;
    const containerRef = useRef(null);

    const [emailsList, setEmailList] = useState([]);

    const getEmailList = async () => {
        const response = await CALL_API(`add-users/email-list`, "get");
        if (response.code === STATUS_CODE.SUCCESS) setEmailList(response.response);
    };

    const validateEmployeeId = (rule, value) => {
        if (value && emailsList.includes(value)) {
            return Promise.reject("User ID already exist");
        }
        return Promise.resolve();
    };

    const onFinish = (values) => {
        const currentFormValues = form.getFieldValue();
        setFormValues((prevValues) => ({
            ...prevValues,
            ...currentFormValues,
        }));
        setStep(1)
        // Handle API calls or state updates here
    };

    useEffect(() => {
        getEmailList();
        if (!isEmpty(formValues)) {
            form.setFieldsValue(formValues);
        }
    }, [formValues])


    return (
        <div>
            <Form
                className=""
                form={form}
                // name="addemployeeform"
                layout="vertical"
                initialValues={{
                    firstname: "",
                    lastname: "",
                    salutation: "",
                    othernames: "",
                    dateofbirth: "",
                    gender: "",
                    dependent: "",
                    maritalstatus: "",
                    phoneno: "",
                    user_id: "",
                    postaladdress: "",
                    address: "",
                    password: "",
                    country: "",
                    greetings: "",
                    national_id: "",
                    nationality: "",
                    workpermitno: "",
                    workpermitexpirydate: "",
                    emergencycontactname: "",
                    emergencycontactnumber: "",
                    nextofkinrelationship: "",
                    nextofkinpostaladdress: "",
                }}
                onFinish={onFinish}

            >


                <b className="my-2 mx-1">Personal Information:</b>
                <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="firstname"
                            label="First Name"
                            rules={[
                                {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "First name is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="First name"
                                maxLength={50}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="lastname"
                            label="Last Name"
                            rules={[
                                {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Last name is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Last name"
                                maxLength={50}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="salutation"
                            label="Salutation"
                            placeholder="Select Salutation"
                            rules={[
                                {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Salutation is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Salutation"
                                getPopupContainer={() => containerRef.current}
                            >
                                <Option value="Mr.">Mr.</Option>
                                <Option value="Dr.">Dr.</Option>
                                <Option value="Prof.">Prof.</Option>
                                <Option value="Ms.">Ms.</Option>
                                <Option value="Mrs.">Mrs.</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="othernames"
                            label="Other Names"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Other names is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Other name"
                                maxLength={50}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            label="Date of Birth"
                            name="dateofbirth"
                            rules={[
                                {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Date of Birth is required",
                                },
                            ]}
                        >
                            <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                placeholder="Select Date of Birth"
                                getPopupContainer={() => containerRef.current}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            name="gender"
                            label="Gender"
                            rules={[
                                {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Gender is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select gender"
                                getPopupContainer={() => containerRef.current}
                            >
                                <Option value="male">Male</Option>
                                <Option value="female">Female</Option>
                                <Option value="They/ Them">They/ Them</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="dependent" label="No. of Dependants">
                            <Input
                                placeholder="No. of Dependants"
                                maxLength={10}
                                onKeyDown={(e) => {
                                    const value = /^[0-9+]$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            name="maritalstatus"
                            label="Marital Status"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "This field is required",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select"
                                getPopupContainer={() => containerRef.current}
                            >
                                <Option value="single">Single</Option>
                                <Option value="married">Married</Option>
                                <Option value="widowed">Widowed</Option>
                                <Option value="divorced">Divorced</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="phoneno" label="Mobile Number">
                            <PhoneInput
                                placeholder="Mobile Number"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value = /^[0-9+]$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="user_id"
                            label="Email address"
                            rules={[
                                {
                                    required: true,
                                    pattern: EMAIL_REGEX,
                                    message: "Email ID is required",
                                },
                                { validator: validateEmployeeId },
                            ]}
                        >
                            <Input placeholder="Email ID" maxLength={60} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="postaladdress"
                            label="Postal address"
                            rules={[
                                {
                                    required: false,
                                    message: "Postal address is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Postal address"
                                maxLength={60}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9,/]$/.test(e.key) ||
                                        /^[a-zA-Z\s,/]+$/.test(e.key);
                                    if (!value && e.key !== "Backspace") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="address"
                            label="Physical address"
                            rules={[
                                {
                                    required: false,
                                    message: "Physical address is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Physical address"
                                maxLength={60}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9,/]$/.test(e.key) ||
                                        /^[a-zA-Z\s,/]+$/.test(e.key);
                                    if (!value && e.key !== "Backspace") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: true,
                                    pattern: NON_EMPTY_REGEX,
                                    validator: (_, value) => {
                                        if (value && value.length < 8) {
                                            return Promise.reject(
                                                "Password should be min 8 to max 15 characters."
                                            );
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                },
                            ]}
                        >
                            <Input.Password
                                placeholder="Password"
                                autoComplete="new-password"
                                maxLength={15}
                            />
                        </Form.Item>
                    </Col> */}
                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            name="country"
                            label="Country"
                            rules={[{ required: false, message: "Country is required" }]}
                        >
                            <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                getPopupContainer={() => containerRef.current}
                            >
                                {countryList?.map((country, index) => (
                                    <Select.Option
                                        key={index}
                                        value={country}
                                        label={country}
                                    >
                                        {country}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            name="greetings"
                            label="Greetings"
                            rules={[
                                { required: false, message: "Greetings is required" },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                getPopupContainer={() => containerRef.current}
                            >
                                {Greetings?.map((value, index) => (
                                    <Select.Option key={index} value={value} label={value}>
                                        {value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="national_id" label="National ID/Passport Number">
                            <Input
                                placeholder="National ID/Passport Number"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9]$/?.test(e.key) || /^[a-zA-Z\s]+$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            name="nationality"
                            label="Nationality"
                            rules={[{ required: false, message: "Nationality is required" }]}
                        >
                            <Select
                                showSearch
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                getPopupContainer={() => containerRef.current}
                            >
                                {countryList?.map((country, index) => (
                                    <Select.Option
                                        key={index}
                                        value={country}
                                        label={country}
                                    >
                                        {country}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col xs={24} sm={24} md={12}>
                        <Form.Item name="workpermitno" label="Work Permit No:">
                            <Input
                                placeholder="Work Permit No:"
                                type="text"
                                maxLength={10}
                                onKeyDown={(e) => {
                                    const value = /^[0-9]$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12} ref={containerRef}>
                        <Form.Item
                            label="Work permit expiry date"
                            name="workpermitexpirydate"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Work permit expiry date is required",
                                },
                            ]}
                        >
                            <DatePicker
                                className="w-100"
                                format="DD-MM-YYYY"
                                placeholder="Select Work permit expiry date"
                                getPopupContainer={() => containerRef.current}
                            />
                        </Form.Item>
                    </Col>
                </StyleRow>
                <b className="my-2 mx-1">Next Of Kin Details:</b>
                <StyleRow className="m-1 mt-2 mb-3" gutter={20}>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="emergencycontactname"
                            label="Emergency Contact Name"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Emergency Contact Name is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Emergency Contact Name"
                                maxLength={40}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="emergencycontactnumber"
                            label="Emergency Contact Number"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Emergency Contact Name is required",
                                },
                            ]}
                        >
                            <PhoneInput
                                placeholder="Emergency Contact Number"
                                maxLength={20}
                                onKeyDown={(e) => {
                                    const value = /^[0-9+]$/?.test(e.key);
                                    if (!value && e.key !== "Backspace" && /^[0-9+]$/) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="nextofkinrelationship"
                            label="Relationship"
                            rules={[
                                {
                                    required: false,
                                    pattern: NON_EMPTY_REGEX,
                                    message: "Relationship is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Relationship"
                                maxLength={15}
                                onKeyDown={(e) => {
                                    const allowedChars = /^[a-zA-Z\s]+$/;
                                    if (!allowedChars.test(e.key)) {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <Form.Item
                            name="nextofkinpostaladdress"
                            label="Postal addres"
                            rules={[
                                {
                                    required: false,
                                    message: "Postal addres is required",
                                },
                            ]}
                        >
                            <Input
                                placeholder="Postal addres"
                                maxLength={60}
                                onKeyDown={(e) => {
                                    const value =
                                        /^[0-9,/]$/.test(e.key) ||
                                        /^[a-zA-Z\s,/]+$/.test(e.key);
                                    if (!value && e.key !== "Backspace") {
                                        e.preventDefault();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </StyleRow>
                <div className="text-end">

                    <Form.Item>
                        <Button
                            // loading={showLoader}
                            type="primary"
                            htmlType='submit'
                        // onClick={() => setStep(step + 1)}
                        >
                            Next
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default PersonalForm